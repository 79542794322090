import React from 'react';
import CountUp from 'react-countup';
import {NavLink} from "react-router-dom";

const bnr1 = require('./../../images/background/bg-11.jpg');

const services = [
	{
		image: require('./../../images/uplink/env.jpg'),
		title: '생활 환경',
		description: '홈 IoT, IoT교육, 다중이용시설 관리, 환경 관리 등'
	},
	{
		image: require('./../../images/uplink/smartCity.jpg'),
		title: '스마트 도시',
		description: '스마트 버스정류장, 아파트, 학교 등의 IoT 솔루션'
	},
	{
		image: require('./../../images/uplink/smartFactory.jpg'),
		title: '스마트 공장',
		description: '공장 내 기기 모니터링, 자동 제어 및 데이터 관리 / 분석 등'
	},
	{
		image: require('./../../images/uplink/mobileApp.png'),
		title: '커스터마이징',
		description: '클라이언트 맞춤형 플랫폼 개발 및 기능 추가'
	}
]

const HowItWorkUplink = () => {
	return (
		<>
			<div className="section-full p-t80 p-b40  overlay-wraper bg-cover bg-no-repeat bg-parallax" style={{paddingBottom: '100px'}}>
				<div className="overlay-main site-bg-secondry opacity-09" style={{backgroundColor: 'white'}}/>
				<div className="container">
					{/* TITLE START*/}
					<div className="section-head center wt-small-separator-outer text-center text-white">
						<div className="wt-small-separator site-text-primary">
							<div className="sep-leaf-left"/>
							<div style={{fontSize: '28px', fontWeight: '600'}}>적용 분야</div>
							<div className="sep-leaf-right"/>
						</div>
					</div>
					<div className="row d-flex justify-content-center">
							{services.map((item, index) => (
								<div key={index} className="col-lg-3 col-md-6 col-sm-12 m-b50">
									<div className="service-border-box service-image-box">
										<div className="wt-box service-box-1 bg-white">
											<div className="service-box-1-media m-b20">
												<img src={item.image.default} alt="" loading="lazy"/>
											</div>
											<div className="service-box-title title-style-2 site-text-secondry">
												<span className="s-title-one" style={{fontSize: '21px', fontWeight: '500'}}>{item.title}</span>
												<span className="s-title-two"/>
											</div>
											<div className="service-box-content" style={{height: 50}}>
												<p style={{fontSize: '14px'}}>{item.description}</p>
												{/*<NavLink to={"/services-mechanical"} className="site-button-link">Read More</NavLink>*/}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					{/*<img src={require('./../../images/uplink/howItWorks-1.png').default} alt="" style={{paddingBottom: '70px'}}/>*/}
					{/*<img src={require('./../../images/uplink/howItWorks-2.png').default} alt=""/>*/}
				</div>
			</div>
		</>
	);
};

export default HowItWorkUplink;
