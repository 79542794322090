// import Home1 from './Pages/Home1';
// import Home2 from './Pages/Home2';
// import Home3 from './Pages/Home3';
// import Home5 from './Pages/Home5';
// import Home6 from './Pages/Home6';
// import Home7 from './Pages/Home7';
// import Home8 from './Pages/Home8';
// import Home9 from './Pages/Home9';
// import Home10 from './Pages/Home10';
// import Home11 from './Pages/Home11';
// import Home12 from './Pages/Home12';
// import About1 from './Pages/About1';
// import About2 from './Pages/About2';
// import Services1 from './Pages/Services1';
// import ServicesAgricultural from './Pages/ServicesAgricultural';
// import ServicesAutomotive from './Pages/ServicesAutomotive';
// import ServicesChemical from './Pages/ServicesChemical';
// import ServicesCivil from './Pages/ServicesCivil';
// import ServicesMechanical from './Pages/ServicesMechanical';
// import ServicesOilgas from './Pages/ServicesOilgas';
// import ServicesPowerEnergy from './Pages/ServicesPowerEnergy';
// import ProjectMasonry from './Pages/ProjectMasonry';
// import ProjectCarousel from './Pages/ProjectCarousel';
// import ProjectDetail from './Pages/ProjectDetail';
// import Shop from './Pages/Shop';
// import ShopDetail from './Pages/ShopDetail';
// import ShoppingCart from './Pages/ShoppingCart';
// import Checkout from './Pages/Checkout';
// import Wishlist from './Pages/Wishlist';
// import BlogGrid from './Pages/BlogGrid';
// import BlogList from './Pages/BlogList';
// import BlogPost from './Pages/BlogPost';
// import PricingPlan from './Pages/PricingPlan';
// import IconFont from './Pages/IconFont';
// import Team from './Pages/Team';
// import TeamSingle from './Pages/TeamSingle';
// import Faq from './Pages/Faq';

import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Home4 from './Pages/Home4';
import SionServicePage from './Pages/SionServicePage';
import UplinkPlusServicePage from './Pages/UplinkPlusServicePage';
import ProjectGrid from './Pages/ProjectGrid';
import Error403 from './Pages/Error403';
import Error404 from './Pages/Error404';
import Error405 from './Pages/Error405';
import ContactUs from './Pages/ContactUs';

class Components extends React.Component {
	render() {
		return (
			<BrowserRouter basename="/">
				<div className="page-wraper">
					<Switch>
						<Route path='/' exact component={Home4}/>
						<Route path='/sion' exact component={SionServicePage}/>
						<Route path='/uplinkplus' exact component={UplinkPlusServicePage}/>
						<Route path='/project-grid' exact component={ProjectGrid}/>
						
						<Route path='/error403' exact component={Error403}/>
						<Route path='/error404' exact component={Error404}/>
						<Route path='/error405' exact component={Error405}/>
						
						<Route path='/contactus' exact component={ContactUs}/>
						<Route component={Error}/>
					</Switch>
				</div>
			</BrowserRouter>
		);
	};
};

export default Components;
