import React from 'react';
import CountUp from 'react-countup';


const HowItWorkSion = () => {
	return (
		<>
			<div className="section-full p-t80 p-b40 overlay-wraper bg-cover bg-no-repeat bg-parallax" style={{paddingBottom: '160px'}}>
				<div className="overlay-main site-bg-secondry opacity-09" style={{backgroundColor: 'white'}}/>
				<div className="container">
					{/* TITLE START*/}
					<div className="section-head center wt-small-separator-outer text-center text-white">
						<div className="wt-small-separator site-text-primary">
							<div className="sep-leaf-left"/>
							<div style={{fontFamily: '"Teko"'}}>How It Works?</div>
							<div className="sep-leaf-right"/>
						</div>
					</div>
					<img src={require('./../../images/sion/howItWorks-1.jpg').default} alt="" loading="lazy"/>
				</div>
			</div>
		</>
	);
};

export default HowItWorkSion;
