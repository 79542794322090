import React from 'react';
import GoogleMaps from "simple-react-google-maps"
import mailgun from 'mailgun-js'
import Header from './../Parts/Header';
import Footer from './../Parts/Footer';
import Banner from './../Segments/Banner';
import {notification, Modal} from 'antd';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const bnrimg = require('./../../images/contactUs/bannerContact.jpg');
const bnr1 = require('./../../images/contactUs/contactUsMain.jpg');
const bgimg = require('./../../images/background/bg-map2.png');

const ContactUs = () => {
	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [subject, setSubject] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [isModalVisible, setIsModalVisible] = React.useState(false);
	
	let nameRef = React.useRef(null);
	let emailRef = React.useRef(null);
	let phoneRef = React.useRef(null);
	let subjectRef = React.useRef(null);
	let messageRef = React.useRef(null);
	
	const DOMAIN = "mail.uplink.co.kr";
	const mg = mailgun({apiKey: "63141a635df411d266d851a7ec3cf27c-4de08e90-c032b35f", domain: DOMAIN});
	const data = {
		from: "Uplink C/S <no_reply@mail.uplink.co.kr>",
		to: "info@uplink.co.kr", // info@uplink.co.kr
		subject: "Customer Question", // Customer Question
		template: "uplink_cokr_contact",
		'h:X-Mailgun-Variables': JSON.stringify({
			"main_title": "웹사이트를 통한 질문", // 웹사이트를 통한 질문
			"cname": name,
			"phone": phone,
			"email": email,
			"title": subject,
			"remark": message
		})
	};
	
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	
	const sendMail = () => {
		// console.log('[EMAIL DATA] => ', data)
		mg.messages().send(data, function (error, body) {
			// console.log(body);
			openNotification();
			nameRef.current.value = ''
			emailRef.current.value = ''
			phoneRef.current.value = ''
			subjectRef.current.value = ''
			messageRef.current.value = ''
		});
	}
	
	const openNotification = () => {
		notification.open({
			message: '이메일 전송',
			description:
				'이메일 전송이 완료되었습니다.',
			icon: <FontAwesomeIcon icon={faPaperPlane} className={'site-text-primary'} size={'1x'}/>,
		});
	};

// 	const policyModal = () => {
//   Modal.success({
//     content:
//   });
// }
	
	const showModal = () => {
		setIsModalVisible(true);
	};
	
	const handleOk = () => {
		setIsModalVisible(false);
	};
	
	const handleCancel = () => {
    setIsModalVisible(false);
  };
	
	return (
		<>
			<Header/>
			<div className="page-content">
				{/* INNER PAGE BANNER */}
				<Banner title="문의하기" pagename="Contact Us" bgimage={bnrimg.default}/>
				{/* INNER PAGE BANNER END */}
				{/* SECTION CONTENTG START */}
				{/* CONTACT FORM */}
				<div className="section-full  p-t80 p-b50 bg-cover" style={{backgroundImage: "url(" + bnr1.default + ")"}}>
					{/*<div className="section-full  p-t80 p-b50 bg-cover" style={{backgroundColor: 'white'}}>*/}
					<div className="section-content">
						<div className="container">
							<div className="contact-one">
								{/* CONTACT FORM*/}
								<div className="row  d-flex justify-content-center flex-wrap">
									<div className="col-lg-6 col-md-6 m-b30">
										<form className="cons-contact-form" method="post" action="#">
											{/* TITLE START */}
											{/*<div className="section-head left wt-small-separator-outer">*/}
											{/*	<div className="wt-small-separator site-text-primary">*/}
											{/*		<div className="sep-leaf-left"/>*/}
											{/*		<div>Contact Form</div>*/}
											{/*		<div className="sep-leaf-right"/>*/}
											{/*	</div>*/}
											{/*	<h2>Get In Touch</h2>*/}
											{/*</div>*/}
											{/* TITLE END */}
											<div className="row">
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<input name="username" type="text" required className="form-control" placeholder="이름"
														       ref={nameRef}
														       onChange={e => setName(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<input name="email" type="text" className="form-control" required placeholder="이메일"
														       ref={emailRef}
														       onChange={e => setEmail(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<input name="phone" type="text" className="form-control" required placeholder="전화번호"
														       ref={phoneRef}
														       onChange={e => setPhone(e.target.value)}/>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<input name="subject" type="text" className="form-control" required placeholder="제목"
														       ref={subjectRef}
														       onChange={e => setSubject(e.target.value)}/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group">
														<textarea name="message" className="form-control" rows={4} placeholder="내용"
														          ref={messageRef}
														          defaultValue={""} onChange={e => setMessage(e.target.value)}/>
													</div>
												</div>
												<div className="col-md-12" style={{display: 'flex', flexDirection: 'column'}}>
													<a onClick={() => showModal()}
													   style={{color: '#FE5F14', fontSize: '15px', fontWeight: '500'}}>개인 정보
														수집·이용 전문보기</a>
													<button type="button" style={{width: 160, marginTop: 10}}
													        className="site-button site-btn-effect" onClick={sendMail}>문의하기
													</button>
												</div>
											</div>
										</form>
									</div>
									<div className="col-lg-6 col-md-6 m-b30">
										<div className="contact-info">
											<div className="contact-info-inner">
												{/* TITLE START*/}
												{/*<div className="section-head left wt-small-separator-outer">*/}
												{/*	<div className="wt-small-separator site-text-primary">*/}
												{/*		<div className="sep-leaf-left"/>*/}
												{/*		<div>Contact info</div>*/}
												{/*		<div className="sep-leaf-right"/>*/}
												{/*	</div>*/}
												{/*	<h2>Our Full Info</h2>*/}
												{/*</div>*/}
												{/* TITLE END*/}
												<div className="contact-info-section" style={{backgroundImage: "url(" + bgimg.default + ")"}}>
													<div className="wt-icon-box-wraper left m-b30">
														<div className="icon-content">
															<h3 className="m-t0 site-text-primary">전화번호</h3>
															<p>031) 962 - 1077</p>
														</div>
													</div>
													<div className="wt-icon-box-wraper left m-b30">
														<div className="icon-content">
															<h3 className="m-t0 site-text-primary">이메일</h3>
															<p>info@uplink.co.kr</p>
														</div>
													</div>
													<div className="wt-icon-box-wraper left m-b30">
														<div className="icon-content">
															<h3 className="m-t0 site-text-primary">주소</h3>
															<p>(10896) 경기도 고양시 일산동구 동국로 32, 612호(동국대학교 산학협력관)</p>
														</div>
													</div>
													{/*<div className="wt-icon-box-wraper left">*/}
													{/*	<div className="icon-content">*/}
													{/*		<h3 className="m-t0 site-text-primary">Opening Hours</h3>*/}
													{/*		<ul className="list-unstyled m-b0">*/}
													{/*			<li>Mon-Fri: 9 am – 6 pm</li>*/}
													{/*			<li>Saturday: 9 am – 4 pm</li>*/}
													{/*			<li>Sunday: Closed</li>*/}
													{/*		</ul>*/}
													{/*	</div>*/}
													{/*</div>*/}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* GOOGLE MAP */}
				{/*<div className="section-full bg-white p-tb80">*/}
				{/*	<div className="section-content">*/}
				{/*		<div className="container">*/}
				{/*			<div className="gmap-outline">*/}
				{/*				<GoogleMaps*/}
				{/*					apiKey={"AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0"}*/}
				{/*					style={{height: "400px", width: "100%"}}*/}
				{/*					zoom={12}*/}
				{/*					center={{lat: 34.073280, lng: -118.251410}}*/}
				{/*					markers={{lat: 34.073280, lng: -118.251410}} //optional*/}
				{/*				/>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/* POLICY START */}
				<Modal title="개인 정보 수집·이용 전문보기" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={[]}>
					<dl>
						<dt>(1) 수집하는 개인정보의 항목 및 수집방법</dt>
						<dd style={{padding: '0px 30px'}}>
							<ul>
								<li>
									<span>수집하는 개인정보의 항목</span>
									<ul style={{padding: '0px 20px'}}>
										<li className="li">업링크는 원활한 프로젝트 상담을 위해 아래와 같은 개인정보를 수집하고 있습니다.</li>
										<li className="li">성명, E-mail, 전화번호</li>
									</ul>
								</li>
								<li>
									<span>개인정보 수집방법</span>
									<ul style={{padding: '0px 20px'}}>
										<li className="li">업링크는 이용자가 자발적으로, 구체적으로 기입할 때만 개인정보를 수집하고 있습니다.</li>
									</ul>
								</li>
							</ul>
						</dd>
					</dl>
					<dl>
						<dt>(2) 개인정보 수집 및 이용목적</dt>
						<dd style={{padding: '0px 30px'}}>
							<ul>
								<li>
									<span>업링크는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</span>
									<ul style={{padding: '0px 20px'}}>
										<li className="li">고객문의, 이용문의, 외주개발문의</li>
									</ul>
								</li>
							</ul>
						</dd>
					</dl>
					<dl>
						<dt>(3) 개인정보의 보유 및 이용기간</dt>
						<dd style={{padding: '0px 30px'}}>
							<ul>
								<li>
									<span>업링크는 개인정보 보유 및 이용기간은 작성 시점부터 3년이내로 합니다.</span>
								</li>
							</ul>
						</dd>
					</dl>
				</Modal>
				{/* POLICY END */}
			</div>
			
			<Footer/>
		
		</>
	);
};

export default ContactUs;
