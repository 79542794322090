import React from 'react';
import Header from './../Parts/Header';
import Footer from './../Parts/Footer';
import Banner from './../Segments/Banner';
import Testimonials11 from './../Segments/Testimonials11';
import WhatWeDoSion from "../Segments/WhatWeDoSion";
import ServicesSion from '../Segments/ServicesSion'
import HowItWorkSion from "../Segments/HowItWorkSion";

let bnrimg = require('./../../images/sion/bannerSion.jpg');

const SionServicePage = () => {
	
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	
	return (
		<>
			<Header/>
			<div className="page-content">
				{/* INNER PAGE BANNER */}
				<Banner title="시온" pagename="시온" bgimage={bnrimg.default}/>
				{/* INNER PAGE BANNER END */}
				<WhatWeDoSion/>
				<ServicesSion/>
				<HowItWorkSion />
				<Testimonials11 />
			</div>
			<Footer/>
		</>
	);
	
};

export default SionServicePage;
