import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingHeart, faLaptopHouse } from '@fortawesome/free-solid-svg-icons'

const WhatWeDoSion = () => {
	
	return (
		<>
			<div className="section-full p-t80 p-b50 bg-gray what-we-do-section2">
				<div className="container">
					<div className="section-content what-we-do-content">
						<div className="row d-flex justify-content-center flex-wrap">
							<div className="col-lg-6 col-md-12 m-b30">
								<div className="whatWedo-media-section">
									<div className="wt-media">
										<img src={require('./../../images/sion/whatWeDoSion.jpg').default} alt="" loading="lazy"/>
									</div>
									{/*<div className="whatWedo-media-content text-white">*/}
									{/*	<div className="whatWedo-media-inner">*/}
									{/*		<h3>Team Working Dedicatedly</h3>*/}
									{/*		<p>We have 26+ years of experience with providing wide area of specialty services works listed*/}
									{/*			below</p>*/}
									{/*	</div>*/}
									{/*</div>*/}
								</div>
							</div>
							<div className="col-lg-6 col-md-12 m-b40">
								<div className="whatWedo-info-section">
									{/* TITLE START*/}
									<div className="left wt-small-separator-outer">
										<div className="wt-small-separator site-text-primary">
											<div className="sep-leaf-left"/>
											<div style={{fontFamily: '"Teko"'}}>Sion</div>
											<div className="sep-leaf-right"/>
										</div>
										<h3>노인 / 장애인을 위한</h3>
										<h3>AAC기반 홈 IoT 서비스</h3>
										<p>- 노인 & 장애인 친화 UI 및 기능(스마트씬)</p>
										<p>- 노인 / 장애인의 생활유형별 서비스 템플릿 제공</p>
										<p>- 보호자를 위한 관리 도구 및 기능</p>
										<p>- 원하는 기기를 손쉽게 연결할 수 있는 ZigBee 허브</p>
									</div>
									{/* TITLE END*/}
									<div className="wt-icon-card-outer">
										<div className="wt-icon-card bg-white shadow">
											<div className="wt-card-header" style={{display: 'flex'}}><FontAwesomeIcon icon={faHandHoldingHeart} className={'site-text-primary'} size={'2x'} style={{marginRight: 12}}/><span
												className="title-style-2 site-text-secondry" style={{fontSize: '19px', fontWeight: '600'}}>노인/장애인 친화 인터페이스 & 기능</span></div>
											<div className="wt-card-content"><p>사용자를 고려한 AAC 기반의 인터페이스 및 제어 기능</p></div>
										</div>
										<div className="wt-icon-card bg-white shadow">
											<div className="wt-card-header" style={{display: 'flex'}}><FontAwesomeIcon icon={faLaptopHouse} className={'site-text-primary'} size={'2x'} style={{marginRight: 12}}/><span
												className="title-style-2 site-text-secondry" style={{fontSize: '19px', fontWeight: '600'}}>원하는 기기를 쉽게 연결</span></div>
											<div className="wt-card-content"><p>사용자가 원하는 기기를 쉽게 추가하여 사용 가능</p></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="hilite-large-title title-style-2">
					<span>What we do</span>
				</div>
			</div>
		</>
	);
	
};

export default WhatWeDoSion;
