import React from 'react';
import Header from './../Parts/Header';
import Footer from './../Parts/Footer';
import Banner from './../Segments/Banner';
import WhatWeDoUplink from "../Segments/WhatWeDoUplink";
import ServicesUplink from "../Segments/ServicesUplink";
import HowItWorkUplink from "../Segments/HowItWorkUplink";

const bnrimg = require('./../../images/uplink/bannerUplink.jpg');

const UplinkPlusServicePage = () => {
	
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	
	return (
		<>
			<Header/>
			<div className="page-content">
				{/* INNER PAGE BANNER */}
				<Banner title="업링크+" pagename="업링크+" bgimage={bnrimg.default}/>
				{/* INNER PAGE BANNER END */}
				<WhatWeDoUplink/>
				<ServicesUplink/>
				<HowItWorkUplink />
			</div>
			<Footer/>
		</>
	);
	
};

export default UplinkPlusServicePage;
