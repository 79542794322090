import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChartBar, faUniversalAccess} from '@fortawesome/free-solid-svg-icons'

class WhatWeDo2 extends React.Component {
	render() {
		return (
			<>
				<div className="section-full p-t80 p-b50 bg-gray what-we-do-section2">
					<div className="container">
						<div className="section-content what-we-do-content">
							<div className="row d-flex justify-content-center flex-wrap">
								<div className="col-lg-6 col-md-12 m-b30">
									<div className="whatWedo-media-section">
										<div className="wt-media">
											<img src={require('./../../images/home/homeWhatWeDo.jpg').default} loading="lazy" alt=""/>
										</div>
										{/*<div className="whatWedo-media-content text-white">*/}
										{/*    <div className="whatWedo-media-inner">*/}
										{/*        <h3>Help transportation and logistics companies</h3>*/}
										{/*        <p>Representative logistics operator providing full range of service in the sphere of customs.</p>*/}
										{/*    </div>*/}
										{/*</div>*/}
									</div>
								</div>
								<div className="col-lg-6 col-md-12 m-b40">
									<div className="whatWedo-info-section">
										{/* TITLE START*/}
										<div className="left wt-small-separator-outer">
											<div className="wt-small-separator site-text-primary">
												<div className="sep-leaf-left"/>
												<div style={{fontFamily: '"Teko"'}}>What We do</div>
												<div className="sep-leaf-right"/>
											</div>
											<h3 style={{whiteSpace: 'pre-line'}}>{'클라이언트의 서비스 환경에\n최적화된 맞춤형 IoT 플랫폼 솔루션 개발'}</h3>
											<p>[적용 사례] 메뉴에서 다양한 적용사례를 확인할 수 있습니다.</p>
										</div>
										{/* TITLE END*/}
										<div className="wt-icon-card-outer">
											<div className="wt-icon-card bg-white shadow" onClick={() => window.location = '/uplinkplus'}
											     style={{cursor: 'pointer'}}>
												<div className="wt-card-header" style={{display: 'flex', alignItems: 'center'}}>
													<FontAwesomeIcon icon={faChartBar}
													                 className={'site-text-primary'} size={'2x'}
													                 style={{marginRight: 12}}/>
													<span className="title-style-2 site-text-secondry" style={{fontSize: '23px', fontWeight: '600'}}>업링크 플러스</span>
												</div>
												<div className="wt-card-content"><p>스마트 도시, 스마트 공장, 생활 환경, 학교 등</p></div>
											</div>
											<div className="wt-icon-card bg-white shadow" onClick={() => window.location = '/sion'}
											     style={{cursor: 'pointer'}}>
												<div className="wt-card-header" style={{display: 'flex', alignItems: 'center'}}><FontAwesomeIcon icon={faUniversalAccess}
												                                                 className={'site-text-primary'} size={'2x'}
												                                                 style={{marginRight: 12}}/><span
													className="title-style-2 site-text-secondry"
													style={{fontSize: '23px', fontWeight: '600'}}>시온</span></div>
												<div className="wt-card-content"><p>노인 / 장애인 가정, 복지관, 돌봄 병원 등</p></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="hilite-large-title title-style-2">
						<span>What we do</span>
					</div>
				</div>
			</>
		);
	}
};

export default WhatWeDo2;
