import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNetworkWired, faLaptop } from '@fortawesome/free-solid-svg-icons'

const WhatWeDoUplink = () => {
	
	return (
		<>
			<div className="section-full p-t80 p-b50 bg-gray what-we-do-section2">
				<div className="container">
					<div className="section-content what-we-do-content">
						<div className="row d-flex justify-content-center flex-wrap">
							<div className="col-lg-6 col-md-12 m-b30">
								<div className="whatWedo-media-section">
									<div className="wt-media">
										<img src={require('./../../images/uplink/whatWeDoUplink.jpg').default} alt="" loading="lazy"/>
									</div>
									{/*<div className="whatWedo-media-content text-white">*/}
									{/*	<div className="whatWedo-media-inner">*/}
									{/*		<h3>Team Working Dedicatedly</h3>*/}
									{/*		<p>We have 26+ years of experience with providing wide area of specialty services works listed*/}
									{/*			below</p>*/}
									{/*	</div>*/}
									{/*</div>*/}
								</div>
							</div>
							<div className="col-lg-6 col-md-12 m-b40">
								<div className="whatWedo-info-section">
									{/* TITLE START*/}
									<div className="left wt-small-separator-outer">
										<div className="wt-small-separator site-text-primary">
											<div className="sep-leaf-left"/>
											<div style={{fontFamily: '"Teko"'}}>Uplink+</div>
											<div className="sep-leaf-right"/>
										</div>
										<h3>중소기업을 위한</h3>
										<h3>클라우드 기반 통합 IoT 플랫폼 솔루션</h3>
										<p>- 별도의 개발 없이 디바이스 연결부터 소비자 인터페이스까지 제공</p>
										<p>- 스마트 씬(SMART SCENE) 기능으로 이벤트 제어 자동화 도구 활용</p>
										<p>- 전용 IoT 모듈(Pre-Installed Network Module)로 쉽고 빠른 연결</p>
									</div>
									{/* TITLE END*/}
									<div className="wt-icon-card-outer">
										<div className="wt-icon-card bg-white shadow">
											<div className="wt-card-header" style={{display: 'flex'}}><FontAwesomeIcon icon={faNetworkWired} className={'site-text-primary'} size={'2x'} style={{marginRight: 12}}/><span
												className="title-style-2 site-text-secondry" style={{fontSize: '19px', fontWeight: '600'}}>쉬운 디바이스 연결 & 플랫폼 관리</span></div>
											<div className="wt-card-content">
												<p>특허 기반의 IoT 플랫폼 & 디바이스 연결 기술 지원</p>
											</div>
										</div>
										<div className="wt-icon-card bg-white shadow">
											<div className="wt-card-header" style={{display: 'flex'}}><FontAwesomeIcon icon={faLaptop} className={'site-text-primary'} size={'2x'} style={{marginRight: 12}}/><span
												className="title-style-2 site-text-secondry" style={{fontSize: '19px', fontWeight: '600'}}>실시간 디바이스 & 데이터 관리</span></div>
											<div className="wt-card-content">
												<p>IoT 디바이스 원격 세팅, 제어 및 모니터링 도구</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="hilite-large-title title-style-2">
					<span>What we do</span>
				</div>
			</div>
		</>
	);
};

export default WhatWeDoUplink;
