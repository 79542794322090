import React from 'react';
import Header from './../Parts/Header';
import Footer from './../Parts/Footer';
import Banner from './../Segments/Banner';
import Projects13 from './../Segments/Projects13';

const bnrimg = require('./../../images/home/solutionPage.jpg');

const ProjectGrid = () => {
	
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	
	return (
		<>
			<Header/>
			<div className="page-content">
				{/* INNER PAGE BANNER */}
				<Banner title="프로젝트" pagename="프로젝트" bgimage={bnrimg.default}/>
				{/* INNER PAGE BANNER END */}
				<Projects13/>
			</div>
			<Footer/>
		</>
	);
};

export default ProjectGrid;
