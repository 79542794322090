import React from 'react';
import {NavLink} from 'react-router-dom';

const filters = [
	{label: "UPLINK+", filter: ".uplink"},
	{label: "SION", filter: ".sion"},
	// {label: "OTHER", filter: ".cat-3"},
	// { label: "Oil & Gas", filter: ".cat-4" },
	// { label: "Agriculture", filter: ".cat-5" }
];

const projects = [
	{
		image: require('./../../images/project/smartBus.jpg'),
		title: '스마트 버스 정류장 관리 시스템',
		description: '아파트 단지 내 스마트 버스 정류장의 IoT 기기 실시간 제어 및 데이터 관리 / 분석',
		filter: 'uplink'
	},
	{
		image: require('./../../images/project/smartFactory.jpg'),
		title: '스마트 팩토리 관리 시스템',
		description: '공장 내 다양한 기기들의 원격 제어 및 자동화',
		filter: 'uplink'
	},
	{
		image: require('./../../images/project/healthCare.jpg'),
		title: '생활 건강 기기 관리 시스템',
		description: '공장 내 다양한 기기들의 원격 제어 및 자동화',
		filter: 'sion'
	},
	{
		image: require('./../../images/project/elder.jpg'),
		title: '노인 / 장애인 홈 IoT 시스템',
		description: '노인 및 장애인을 위한 AAC기반 홈 IoT 서비스',
		filter: 'sion'
	},
	{
		image: require('./../../images/project/air.jpg'),
		title: '환경 측정 기기 시스템',
		description: '다양한 환경 오염 측정기기의 원격 관리 및 데이터 수집 / 분석',
		filter: 'uplink'
	},
	{
		image: require('./../../images/project/welfare.jpg'),
		title: '데이터 관리 및 분석 솔루션',
		description: 'IoT를 통해 수집된 데이터의 보안 및 분석',
		filter: 'uplink'
	},
	// {
	//     image: require('./../../images/gallery/thumb/pic7.jpg'),
	//     title: 'Gear Manufacturing',
	//     description: 'High Performance Services For Multiple Insdustries And Technologies!',
	//     filter: 'cat-3'
	// },
	// {
	//     image: require('./../../images/gallery/thumb/pic8.jpg'),
	//     title: 'Oil Pipeline Project',
	//     description: 'Committed to delivering high quality projects and innovate business.',
	//     filter: 'cat-2'
	// },
	// {
	//     image: require('./../../images/gallery/thumb/pic9.jpg'),
	//     title: 'Industry Complex',
	//     description: 'High Performance Services For Multiple Insdustries And Technologies!',
	//     filter: 'cat-1'
	// }
];

class Projects13 extends React.Component {
	
	render() {
		return (
			<>
				<div className="section-full p-t80 p-b50 bg-white">
					<div className="container">
						{/* PAGINATION START */}
						<div className="filter-wrap work-grid p-b30 text-center">
							<ul className="masonry-filter link-style ">
								<li className="active"><NavLink to={"#"} data-filter="*" style={{fontFamily: '"Teko"'}}>All Cases</NavLink></li>
								{filters.map((item, index) => (
									<li key={index}><NavLink to={"#"} data-filter={item.filter} style={{fontFamily: '"Teko"'}}>{item.label}</NavLink></li>
								))}
							</ul>
						</div>
						{/* PAGINATION END */}
						{/* GALLERY CONTENT START */}
						<div className="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
							{projects.map((item, index) => (
								<div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-6 col-sm-6 m-b30`}>
									<div className="project-img-effect-1">
										<img src={item.image.default} alt="" loading="lazy"/>
										<div className="wt-info" style={{height: 120}}>
											<h4 className="wt-tilte m-b10 m-t0">{item.title}</h4>
											<p>{item.description}</p>
											{/*<NavLink to={"#"} className="site-button-link" style={{position: 'absolute', bottom: 15}} >Read More</NavLink>*/}
										</div>
										{/*<a href={item.image.default} className="mfp-link"><i className="fa fa-search-plus"/></a>*/}
									</div>
								</div>
							))}
						</div>
						{/* GALLERY CONTENT END */}
					</div>
				</div>
			</>
		);
	}
};

export default Projects13;
